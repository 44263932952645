@layer base {
  /*
  Add CSS wisely in this file.
  All styles are shared with all Next projects
*/

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--sk-font-families-poppins);
}

/* This is handled by the headerFooterFO/Header module */
html .app-Body--noScroll {
  overflow: hidden;
}

/* Used for the Job Search Autocomplete feature when the backdrop is enabled */
html .app-Body--jobSearchAutoCompleteIsOpen main {
  position: relative;
  z-index: var(--z-index-website-menu);
}

@media (width < 1312px /* --sk-breakpoint-lg */) {
  html .app-Body--noScrollForMediumAndLessScreen {
    overflow: hidden;
  }
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

}